($ => {

	window.LeapMap = function (mapId, options) {

		options = $.extend({
			maxZoom: typeof LeapMapPins.maxZoom === 'undefined' ? null : LeapMapPins.maxZoom,
			minZoom: typeof LeapMapPins.minZoom === 'undefined' ? null : LeapMapPins.minZoom,
			styles: typeof LeapMapPins.styles === 'undefined' ? [] : LeapMapPins.styles,
			locations: [],
			lat: null,
			lng: null,
			zoom: 15
		}, options);

		return  $.extend({
			mapId,
			markers: [],
			positions: [],
			center: null,
			clearAllMarkers() {
				this.markers.forEach(marker => {
					marker.setMap(null);
				});
				this.positions = [];
				this.markers = [];
			},
			addMarkers(term = null) {
				if (this.locations.length) {
					let locations = this.locations;
					if (term) {
						locations = locations.filter((location) => {
							return location.term_id == term;
						});
					}
					let timeout = 0;
					locations.forEach((location) => {
						this.addMarkerWithTimeout(location, timeout);
						timeout += 5;
					});
			}
			},
			addMarkerWithTimeout(location, timeout) {
				let pinLatLng = new google.maps.LatLng(location.pin_location);
				this.positions.push(pinLatLng);
				setTimeout(() => {
					let icon = null;
					if (typeof location.styles !== 'undefined') {
						if (location.styles.icon) {
							icon = {
								url: location.styles.icon,
								size: new google.maps.Size(location.styles.width, location.styles.height),
								scaledSize: new google.maps.Size(24, location.styles.height / location.styles.width * 24),
								origin: new google.maps.Point(0, 0),
								anchor: new google.maps.Point(12, 12),
							};
						} else if (location.styles.colour) {
							icon = {
								path: google.maps.SymbolPath.CIRCLE,
								fillColor: location.styles.colour,
								fillOpacity: .9,
								strokeColor: location.styles.colour,
								scale: 7,
							};
						}
					}
					let infoWindow = new google.maps.InfoWindow({
						content: location.content_string
					});
					infoWindow.addListener('closeclick', this.centerMap.bind(this));
					let marker = this.addMarker(location, pinLatLng, icon, infoWindow);
					this.markers.push(marker);
				}, timeout);
			},
			addMarker(location, latLng, icon, infoWindow) {
				let marker = new google.maps.Marker({
					title: location.display_name,
					ID: location.ID,
					position: latLng,
					map: this.map,
					icon: icon,
					animation: google.maps.Animation.DROP,
				});
				marker.infoWindow = infoWindow;
				google.maps.event.addListener(marker, 'click', (marker => {
					return () => {
						this.closeInfoWindows();
						infoWindow.open(this.map, marker);
					}
				})(marker));
				return marker;
			},
			openInfoWindow(post) {
				this.markers.forEach(marker => {
					marker.infoWindow.close();
					if (marker.ID == post) {
						marker.infoWindow.open(this.map, marker);
					}
				});
			},
			closeInfoWindows() {
				this.markers.forEach(marker => {
					marker.infoWindow.close();
				});
			},
			centerMap() {
				console.log(this.lng)
				if (this.lat && this.lng) {
					this.map.setCenter(this.center);
					if (this.zoom) {
						this.map.setZoom(this.zoom);
					}
				} else {
					let bounds = new google.maps.LatLngBounds();
					this.positions.forEach(position => {
						bounds.extend(position);
					});
					this.map.fitBounds(bounds);
				}
			},
			saveCenter() {
				this.center = this.map.getCenter();
			},
			doMap() {
				const mapCanvas = $('[data-map="' + mapId + '"]').get(0);
				if (typeof mapCanvas === 'undefined') {
					return;
				}
				this.map = new google.maps.Map(mapCanvas, {
					zoom: this.zoom,
					center: new google.maps.LatLng(this.lat, this.lng),
					styles: this.styles,
					mapTypeControl: false,
					streetViewControl: false,
					maxZoom: this.maxZoom,
					minZoom: this.minZoom
				});
				this.saveCenter();
				this.clearAllMarkers();
				this.addMarkers();
				this.centerMap();
				google.maps.event.addDomListener(window, 'resize', this.centerMap.bind(this));
			}
		}, options)
	};

	function initmaps() {

		let $mapCanvas = $('[data-map]');

		if (!$mapCanvas.length)
			return;

		window.LeapMaps = [];

		$mapCanvas.each((i, map) => {
			let $map = $(map);
			LeapMaps.push(new LeapMap($map.data('map'), $map.data('options')));
		});
		if (window.google && window.google.maps) {
			LeapMaps.forEach(map => map.doMap());
		} else {
			var script = document.createElement('script');
			script.onload = () => {
				LeapMaps.forEach(map => map.doMap())
			}
			script.src = "https://maps.googleapis.com/maps/api/js?key=" + LeapMapPins.google_maps_api_key;
			document.getElementsByTagName('head')[0].appendChild(script);
		}

		if (window.UIkit) {
			UIkit.util.on('.map_categories_menu', 'show', (e) => {
				let
								$map = $(e.target).closest('[data-map-control]'),
								term = $(e.target).prev('a').data('term'),
								leapMap = LeapMaps.filter(map => map.mapId == $map.data('map-control'))[0];
				leapMap.clearAllMarkers();
				leapMap.addMarkers(term);
				leapMap.centerMap();
			});
			$('.map_categories_menu').find('[data-location]').click(function (e) {
				e.preventDefault();
				let
								$map = $(e.target).closest('[data-map-control]'),
								location = $(this).data('location'),
								leapMap = LeapMaps.filter(map => map.mapId == $map.data('map-control'))[0];
				leapMap.openInfoWindow(location);
			});
			$('[data-click="close"]').click(e => {
				e.preventDefault();
				$(e.target).closest('aside').removeClass('open');
			});
			$('[data-click="open"]').click(e => {
				e.preventDefault();
				$(e.target).closest('aside').addClass('open');
			});
			$('[data-click="reset"]').click(e => {
				e.preventDefault();
				let
								$map = $(e.target).next('[data-map-control]'),
								leapMap = LeapMaps.filter(map => map.mapId == $map.data('map-control'))[0];
				leapMap.clearAllMarkers();
				leapMap.addMarkers();
				leapMap.centerMap();
			});
		}
	}
	$(document).ready(initmaps);
})(jQuery)